<template>
    <div>
        <div class="col-md-6">
            <img src="../assets/login-register.jpg" height="140px" style="margin-top:15%">
        </div>
        <div class="col-md-6">
            <form method="POST" @submit.prevent="checkForm">
                <div class="col-md-8 container-fluid login-form">
                    <h4 class="header">Sign In</h4>
                    <div class="col-md-12">
                        <br>
                        <label>Username</label>
                        <input v-model="form.username" type="text" name="username" id="username" placeholder="Username"
                            class="form-control" required="">
                    </div>
                    <div class="col-md-12">
                        <br>
                        <label>Password</label>
                        <input v-model="form.password" type="password" name="password" id="password" placeholder="Password"
                            class="form-control" required="">
                    </div>
                    <div class="col-md-12">
                        <span class="text-danger text-center" v-if="error">{{ error }}</span>
                    </div>
                    <div class="col-md-12">
                        <br>
                        <input type="submit" value="Sign In" class="btn btn-danger" style="width:100%;">
                    </div>
                    <div class="col-md-12">
                        <br>
                        Forgot password? Inform your admin to get a new one.
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import Swal from "../mixins/Swal";
import Toast from "../mixins/Toast";
import axios from 'axios';

export default {
    mixins: [ Swal, Toast ],
    data() {
        return {
            form: {
                username: '',
                password: '',
            },
            error: null
        }
    },
    methods: {
        async submitForm() {
            try {
                const res = await axios.post('/api/auth/login', this.form);
                if (res.data.error) {
                    this.error = res.data.error;
                    return;
                }
                this.ToastSuccess('You login successfully!');
                localStorage.access_token = res.data.access_token;
                this.$router.push({ name: 'Inventory' });
            }
            catch (err) {
                console.log(err)
            }
        },
        checkForm: function () {
            if (this.form.username != '' && this.form.password != '') {
                this.submitForm();
                return;
            }

            this.resetForm();
        },

        resetForm() {
            this.form.username = '';
            this.form.password = '';
        },
    },
}
</script>